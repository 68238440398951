import { useEffect, useRef } from "react";
import { Template } from "@/graphql/gql/graphql";
import Hero from "@/components/home/hero/Hero";
import SportSection from "@/components/home/sport/SportSection";
import { HomeLayout } from "@/components/layout/Layout";
import BetScoreBoardContainer from "@/components/scoreboard/ScoreboardContainer";
import AuthorContainer from "@/components/home/author/AuthorContainer";
import ContainerBoundary from "@/components/common/ContainerBoundary";
import { dehydrate, QueryClient } from "@tanstack/react-query";
import logger from "@/utils/logger";
import { NextSeo } from "next-seo";
import { HOME_PAGE_VIEW } from "@/constants/amplitude";
import { useAmplitude } from "@/utils/hooks/useAmplitude";
import useHomepage from "@/utils/hooks/useHomepage";
import styles from "./index.module.scss";
import { loadHeader, loadSports, loadHomepage } from "../utils/ssr";
import { SEO } from "../constants/index";

const Home = () => {
  const titleRef = useRef("");
  const { setPage, logAmplitudeEvent, isActive } = useAmplitude();
  const { data: homepageData } = useHomepage();
  const articles = homepageData?.articles ?? [];
  const selectedSports = homepageData?.selectedSports ?? [];
  const tools = homepageData?.tools ?? [];
  const template = homepageData?.template ?? Template.Template1;

  useEffect(() => {
    if (titleRef.current !== SEO.RESEARCH_PAGE_TITLE && isActive) {
      titleRef.current = SEO.RESEARCH_PAGE_TITLE;
      setPage("home");
      logAmplitudeEvent(HOME_PAGE_VIEW, {
        "Page Title": SEO.RESEARCH_PAGE_TITLE,
        "Page Name": "home",
      });
    }
  }, [setPage, logAmplitudeEvent, isActive]);

  return (
    <>
      <NextSeo
        title={SEO.RESEARCH_PAGE_TITLE}
        description={SEO.SEO_DESCRIPTION}
        canonical={SEO.FANDUEL_URL_RESEARCH}
      />
      <main className={styles.mainContent}>
        <div className={styles.heroContainer}>
          <Hero articles={articles} template={template} tools={tools} />
        </div>
        <div className={styles.boundaryContainer}>
          <ContainerBoundary>
            <div className={styles.conditionalColsContainer}>
              <div className={styles.conditionalCols}>
                <div className={styles.sectionContainer}>
                  <BetScoreBoardContainer />
                </div>
                <div className={styles.sectionContainer}>
                  {selectedSports.length ? (
                    <SportSection selectedSports={selectedSports} />
                  ) : undefined}
                </div>
              </div>
            </div>
            <div className={styles.sectionContainer}>
              <AuthorContainer />
            </div>
          </ContainerBoundary>
        </div>
      </main>
    </>
  );
};

export default Home;

export async function getServerSideProps() {
  try {
    const queryClient = new QueryClient();
    await Promise.all([
      loadHeader(queryClient),
      loadHomepage(queryClient),
      loadSports(queryClient),
    ]);
    return {
      props: {
        dehydratedState: dehydrate(queryClient),
      },
    };
  } catch (err) {
    logger.error("Error: homepage", err);
    return {
      notFound: true,
    };
  }
}

Home.getLayout = HomeLayout;
