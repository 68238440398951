import ArticleCard from "@/components/articleCard";
import Divider from "@/components/divider";
import AuthorSpotlight from "@/components/author/spotlight/AuthorSpotlight";
import SpotlightArticle from "@/components/spotlightArticle";
import { AuthoredContentItem } from "@/types/common";
import styles from "./template3Column.module.scss";

type Template3ColumnProps = {
  col1Article: AuthoredContentItem;
  col2Articles: AuthoredContentItem[];
  col3Articles: AuthoredContentItem[];
};

const Template3Column = ({
  col1Article,
  col2Articles = [],
  col3Articles = [],
}: Template3ColumnProps) => {
  const {
    title,
    description,
    slug,
    modifiedDate,
    thumbnailImageUrl,
    author,
    sport: { name: sportName, slug: sportSlug },
  } = col1Article;

  return (
    <div className={styles.container}>
      <div className={styles.spotlightArticle}>
        <SpotlightArticle
          author={author}
          fullwidthMobileImage={false}
          image={thumbnailImageUrl?.url}
          link={`/${slug}`}
          origin="author_list"
          publishedOn={modifiedDate}
          snippet={description}
          sportName={sportName}
          sportSlug={sportSlug}
          tagType="neutral"
          title={title}
        />
      </div>
      <div className={styles.articleCardsContainer}>
        <div className={styles.articlesSection}>
          {col2Articles.map((article, idx) => (
            <div key={article.id}>
              <ArticleCard
                author={article.author}
                image={article.thumbnailImageUrl?.url}
                link={`/${article.slug}`}
                origin="author_list"
                publishedOn={article.modifiedDate}
                sportName={article.sport.name}
                sportSlug={article.sport.slug}
                title={article.title}
                displayHorizontal
              />
              {idx < col2Articles.length - 1 && <Divider color="light" />}
            </div>
          ))}
        </div>
        <div className={styles.divider}>
          <Divider color="light" />
        </div>
        <div className={styles.articlesSection}>
          {col3Articles.map((article, idx) => (
            <div key={article.id}>
              <ArticleCard
                author={article.author}
                image={article.thumbnailImageUrl?.url}
                link={`/${article.slug}`}
                origin="author_list"
                publishedOn={article.modifiedDate}
                sportName={article.sport.name}
                sportSlug={article.sport.slug}
                title={article.title}
                displayHorizontal
              />
              {idx < col3Articles.length - 1 && <Divider color="light" />}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.authorSpotlight}>
        <AuthorSpotlight />
      </div>
    </div>
  );
};

export default Template3Column;
