import { SelectedSport } from "@/graphql/gql/graphql";
import Divider from "@/components/divider";
import ErrorComponent from "@/components/empty/ErrorComponent";
import FeaturedSport from "./FeaturedSport";
import styles from "./sportSection.module.scss";

type SportSectionProps = {
  selectedSports: SelectedSport[];
};

const SportSection = ({ selectedSports }: SportSectionProps) => (
  <div className={styles.container}>
    {selectedSports.length === 0 ? (
      <div className={styles.boxError}>
        <ErrorComponent />
      </div>
    ) : (
      <>
        <FeaturedSport selectedSport={selectedSports[0]} />
        <div className={styles.boxDivider}>
          <Divider />
        </div>
        <FeaturedSport selectedSport={selectedSports[1]} />
      </>
    )}
  </div>
);

export default SportSection;
