import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { Type } from "@/graphql/gql/graphql";
import { getAuthors } from "../api";

const FEATURED_AUTHORS_QUERY_KEY_BASE = ["featured-authors", Type.Article];

const useAuthors = (sportName = "All") => {
  const { data, isLoading, isError, isFetching } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: [...FEATURED_AUTHORS_QUERY_KEY_BASE, sportName],
    queryFn: async () =>
      getAuthors({
        contentType: Type.Article,
        limit: 30,
        sportName: sportName !== "All" ? sportName : null,
      }),
    select: (data) => {
      const actualAuthors = (data.getAuthors?.authors ?? [])
        .filter((author) => author != null && author.entity != null)
        .map((author) => author!.entity)
        .map((author) => {
          const articles =
            author.authoredContent?.filter(
              (
                article
              ): article is Extract<
                typeof article,
                { __typename: "Article" }
                // The above type assertion tells TS that these list items are all of type "Article"
              > => article?.__typename === "Article"
            ) ?? [];

          return {
            ...author,
            authoredContent: articles,
          };
        })
        .filter((author) => author.authoredContent.length > 0);

      return actualAuthors;
    },
  });

  return {
    data: data ?? [],
    isError,
    isFetching,
    isLoading,
  };
};

export default useAuthors;
