import useAuthorList from "@/utils/hooks/useAuthorList";
import useSports from "@/utils/hooks/useSports";
import Template3Column from "@/components/templates/author/Template3Column";
import Tabs from "@/components/Tabs";
import ErrorComponent from "@/components/empty/ErrorComponent";
import LoadingBackdrop from "@/components/loadingBackdrop/LoadingBackdrop";
import styles from "./authorContainer.module.scss";

const AuthorContainer = () => {
  const { selectableSportsForAuthors } = useSports();
  const { data, setSport, selectedSport, isLoading, isFetching, isError } =
    useAuthorList("All", selectableSportsForAuthors);

  const loading = isLoading || isFetching;
  const success = !isFetching && data;
  const [spotlightArticle, ...remainingArticles] = data ?? [];
  const [col2Articles, col3Articles] = [
    remainingArticles.slice(0, 5),
    remainingArticles.slice(5, 10),
  ];

  return (
    <div className={styles.container}>
      <h3 className={styles.heading}>More from our authors</h3>
      <div className={styles.tabsContainer}>
        <Tabs
          dataTestId="sports-tabs"
          items={selectableSportsForAuthors.map(({ name }) => name)}
          selected={selectedSport}
          setSelected={setSport}
        />
      </div>
      <div className={styles.cardContent}>
        <LoadingBackdrop loading={loading} />
        {isError && <ErrorComponent ctaButtonLabel="Refresh Module" />}
        {success && (
          <Template3Column
            col1Article={spotlightArticle}
            col2Articles={col2Articles}
            col3Articles={col3Articles}
          />
        )}
      </div>
    </div>
  );
};

export default AuthorContainer;
